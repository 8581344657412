import './App.css';
import Root from './router/Root';
import { ConfigProvider } from 'antd';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const mainColor = '#8338EC'; // FF8C00
const secondary = '#3A86FF';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY_FB,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGED_BUCKED,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MESUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

function Main () {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: mainColor,
          colorText: '#333333',
          colorTextSecondary: '#8d8d8d',
          colorBorderSecondary: '#C8CDD0',
          colorBgSpotlight: mainColor,
          colorLink: '#3A86FF'
        },
        components: {
          Menu: { // if you use "dark" theme on menu
            colorItemBg: '#F5F5F5',
            subMenuItemBg: '#F5F5F5'
          },
          Layout: {
            colorBgHeader: '#F5F5F5'
          }
        }
      }}
    >
      <Root />
    </ConfigProvider>
  );
}

export default Main;
