import {
  Badge,
  Button,
  Divider,
  Space,
  Tag,
  Tooltip,
  Typography,
  Popconfirm
} from 'antd';
import { ButtonContainer } from '../prettyJsonScreen.styled';
import { useTranslation } from 'react-i18next';
import {
  CopyOutlined,
  DownloadOutlined,
  HistoryOutlined,
  RollbackOutlined,
  CompressOutlined,
  ExpandOutlined,
  ClockCircleTwoTone,
  SearchOutlined
} from '@ant-design/icons';
import { Editor } from '@monaco-editor/react';
import styles from '../PrettyJsonScreen.module.css';
import { type resultInitialState } from '../../../../hooks/constants';

interface JsonEditorProps {
  visible: boolean
  divisor: boolean
  json: string
  rollback: boolean
  count: number
  title: string
  isTheLastSuccess: boolean
  data: typeof resultInitialState
  compress: boolean
  onToggle: (show: boolean) => void
  onNew: () => void
  onClean: () => void
  onRollback: () => void
  onHistory: () => void
  onDownload: () => void
  onCopy: () => void
  onMinify: () => void
  onSearch: () => void
}

export default function JsonEditor ({
  visible,
  divisor,
  json,
  rollback,
  count,
  title,
  isTheLastSuccess,
  data,
  compress,
  onToggle,
  onNew,
  onClean,
  onRollback,
  onHistory,
  onDownload,
  onCopy,
  onMinify,
  onSearch
}: JsonEditorProps) {
  const { t: translation } = useTranslation();

  if (!visible) return null;

  return (
    <>
      {
        divisor && <Divider />
      }
      <ButtonContainer>
        <Space>
          <Button onClick={() => { onToggle(true); }}>
            { translation('editOriginal') }
          </Button>
          <Button onClick={onNew}>
            { translation('new') }
          </Button>
          <Popconfirm
            title={translation('cleanConfirmTitle')}
            description={translation('cleanConfirmDescription')}
            onConfirm={onClean}
          >
            <Button>
              { translation('cleanAll') }
            </Button>
          </Popconfirm>
        </Space>
        <Space>
          <Tooltip placement="top" title={translation('search')}>
            <Button
              icon={<SearchOutlined />}
              onClick={onSearch}
            />
          </Tooltip>
          {
            rollback && (
              <Tooltip placement="top" title={translation('rollbackLast')}>
                <Button
                  type="primary"
                  danger
                  icon={<RollbackOutlined />} onClick={onRollback}
                />
              </Tooltip>
            )
          }
          <Tooltip placement="top" title={translation('viewHistory')}>
            <Badge count={count} size="small">
              <Button
                disabled={count === 0}
                icon={<HistoryOutlined />}
                onClick={onHistory}
              />
            </Badge>
          </Tooltip>
          <Tooltip placement="top" title={compress ? translation('expand') : translation('minify')}>
            <Button
              icon={ compress ? <ExpandOutlined /> : <CompressOutlined />}
              onClick={onMinify}
            />
          </Tooltip>
          <Tooltip placement="top" title={translation('downloadJson')}>
            <Button icon={<DownloadOutlined />} onClick={onDownload} />
          </Tooltip>
          <Button type="primary" icon={<CopyOutlined />} onClick={onCopy}>
                Copy
          </Button>
        </Space>
      </ButtonContainer>
      <Typography.Text type="secondary">
        <Space>
          <ClockCircleTwoTone />
          { title }
          <Tag color={isTheLastSuccess ? 'blue' : 'cyan'}>
            { isTheLastSuccess ? 'last' : 'history' }
          </Tag>
        </Space>
      </Typography.Text>
      {
        data?.isUrl && (
          <Typography.Text type="secondary">
            <Space>
              { data.request.url }
              <Tag color={data.request.method === 'GET' ? 'green' : 'orange'}>
                { data.request.method }
              </Tag>
            </Space>
          </Typography.Text>
        )
      }
      <div className={styles.editor}>
        <Editor
          defaultLanguage="json"
          value={compress ? data?.jsonMin : json}
          height="70vh"
        />
      </div>
    </>
  );
}
