export const resultInitialState = {
  error: {
    reason: '',
    excerpt: '',
    pointer: '',
    location: {
      start: {
        column: 0,
        line: 0,
        offset: 0
      }
    }
  },
  isValid: false,
  isMinify: false,
  json: '',
  jsonMin: '',
  timestamp: 0,
  alias: '',
  id: '',
  original: '',
  request: {
    method: 'GET',
    isSuccess: true,
    data: null,
    error: {
      message: '',
      status: 0
    },
    url: '',
    body: {} as any
  },
  isUrl: false
};
