import { parse } from '@prantlf/jsonlint';
import { resultInitialState } from '../constants';
import { type ConvertAndValidateJsonOptions } from './useJsonUtil.types';
import dayjs from 'dayjs';
import * as uuid from 'uuid';
import axios from 'axios';

export default function useUtil () {
  const isValidJson = (json: string | undefined) => {
    const jsonResult = {
      isValid: true,
      json: {}
    };

    if (!json) return jsonResult;

    try {
      const result = parse(json);
      jsonResult.json = result;
      jsonResult.isValid = true;
    } catch (error) {
      jsonResult.isValid = false;
    }

    return jsonResult;
  };

  const convertAndValidateJson = (input: string, tabSize?: number, options?: ConvertAndValidateJsonOptions) => {
    const result = { ...resultInitialState };

    try {
      result.error.excerpt = '';
      result.error.reason = '';
      const formattedJson = parse(input, {
        ignoreTrailingCommas: !!options?.autofix,
        ignoreComments: !!options?.ignoreComments
      });

      result.json = JSON.stringify(formattedJson, null, tabSize ?? 4);
      result.jsonMin = JSON.stringify(formattedJson);

      if (options?.minify) result.isMinify = true;

      result.isValid = true;
      result.timestamp = dayjs().unix();
      result.id = uuid.v4();
      result.original = input;
      if (options?.alias) result.alias = options?.alias;
      if (options?.request) {
        result.request = options.request;
        result.isUrl = true;
      }
    } catch (err: any) {
      err.pointer = err?.pointer?.replace(/-/g, '․')?.replace(/․{1}/, '')?.replace('^', '⇡');
      result.error = err;
      result.isValid = false;
    }

    return result;
  };

  const jsonFileGenerator = (jsonString: string, name?: string, minify?: boolean) => {
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    // eslint-disable-next-line no-extra-boolean-cast
    link.download = `${!!name ? name : 'prettier'}${minify ? '.min' : ''}.json`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const requestData = async (url: string, type?: 'GET' | 'POST', body?: unknown) => {
    const request = {
      method: type,
      isSuccess: true,
      data: null,
      error: {
        message: '',
        status: 0
      },
      url,
      body
    };

    const data = await axios(url, {
      method: type,
      data: body
    })
      .catch((error) => {
        return { ...error, isError: true };
      });

    if (data?.data) request.data = data.data;
    if (data?.config?.method === 'post') request.method = 'POST';
    if (data?.isError) {
      request.isSuccess = false;
      request.error.message = data?.message ?? 'Unexpected error';
      request.error.status = data?.response?.status || -1;
    }

    return request;
  };

  return {
    isValidJson,
    convertAndValidateJson,
    jsonFileGenerator,
    requestData
  };
}

export const parseValue = (value: string) => {
  let parsedValue: unknown;
  try {
    parsedValue = JSON.parse(value);
  } catch (error) {
    parsedValue = value;
  }

  return parsedValue;
};
