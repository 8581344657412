export const es = {
  translation: {
    formatt: 'Procesar',
    downloadJson: 'Descargar JSON',
    viewHistory: 'Ver historial',
    minify: 'Comprimir',
    expand: 'Expandir',
    history: 'Historial',
    rollbackLast: 'Ir al último',
    select: 'Seleccionar',
    editOriginal: 'Editar original',
    new: 'Nuevo',
    cleanAll: 'Limpiar',
    cleanConfirmTitle: '¿Borrar todo?',
    cleanConfirmDescription: 'Esta acción borrará todo tu historial',
    copied: 'Se copio en el portapapeles',
    urlDetected: 'Detectamos una URL',
    requestData: 'Solicitar datos',
    ignoreTrailingCommas: 'Ignorar comas al final',
    bodyParamsTitle: 'Body params (JSON):',
    search: 'Buscar',
    close: 'Cerrar',
    searchModal: {
      title: 'Buscar',
      searchByKey: 'Notación de punto',
      searchByFilter: 'Filter',
      searchByFilterDisabled: 'Filter (Array)',
      tooltip: {
        byKey: 'Usa notación de punto para obtener valores. Por ejemplo: ',
        byFilter: 'Crea condicionales usando: '
      },
      placeholder: {
        byKey: 'papa.hija.edad',
        byFilter: 'name==miguel'
      }
    }
  }
};
