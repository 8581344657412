export const en = {
  translation: {
    formatt: 'Formatt',
    downloadJson: 'Download JSON',
    viewHistory: 'View history',
    minify: 'Minify',
    expand: 'Expand',
    history: 'History',
    rollbackLast: 'Rollback to last',
    select: 'Select',
    editOriginal: 'Edit original',
    new: 'New',
    cleanAll: 'Clear',
    cleanConfirmTitle: 'Clear all?',
    cleanConfirmDescription: 'This action will delete all your history',
    copied: 'Already copied!',
    urlDetected: 'URL detected',
    requestData: 'Request data',
    ignoreTrailingCommas: 'Ignore trailing commas',
    bodyParamsTitle: 'Body params (JSON):',
    search: 'Search',
    close: 'Close',
    searchModal: {
      title: 'Search',
      searchByKey: 'Dot notation',
      searchByFilter: 'Filter',
      searchByFilterDisabled: 'Filter (Array)',
      tooltip: {
        byKey: 'Use dot notation to obtain values. For example: ',
        byFilter: 'Create conditionals using: '
      },
      placeholder: {
        byKey: 'parent.child.age',
        byFilter: 'name==mike'
      }
    }
  }
};
