import React from 'react';
import { Layout, Menu } from 'antd';
import { Link, Route, Routes } from 'react-router-dom';
import menuOptions from './menu.options.json';
import PrettyJsonScreen from '../../sections/ToolsDashboard/PrettyJson/PrettyJsonScreen';
import Main from '../../sections/ToolsDashboard/Main/Main';
import { useWindowWidth } from '@react-hook/window-size';
import Title from 'antd/es/typography/Title';

export default function ToolsDashboardScreen () {
  const windowWidth = useWindowWidth();
  const menuWidth = 200;

  return (
    <div style={{ maxWidth: '1432px', margin: '0 auto' }}>
      <Layout>
        {/* <Layout.Sider
          width={menuWidth}
          breakpoint="lg"
        >
          <Title level={4}>
            <div style={{ textAlign: 'center' }}>
            utilsFor;;;
            </div>
          </Title>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={['1']}
          >
            {
              menuOptions.map((menuItem) => (

                <Menu.Item key={menuItem.key}>
                  <Link to={menuItem.path}>
                    {menuItem.label}
                  </Link>
                </Menu.Item>
              ))
            }
          </Menu>
        </Layout.Sider> */}
        <Layout>
          <Layout.Content>
            <div style={{
              minHeight: '100vh',
              overflowX: 'scroll',
              maxWidth: `${windowWidth - menuWidth}px`,
              padding: '36px',
              margin: '0 auto'
            }}>
              <Routes>
                <Route path="/" element={<PrettyJsonScreen />} />
                <Route path="/prettier-json" element={<PrettyJsonScreen />} />
              </Routes>
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    </div>
  );
}
