import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ToolsDashboardScreen from '../screens/ToolsDashboardScreen/ToolsDashboardScreen';

const root = createBrowserRouter([
  {
    path: '/',
    element: <ToolsDashboardScreen />,
    children: [{
      path: 'prettier-json'
    }]
  }
]);

export default function Root () {
  return <RouterProvider router={root} />;
}
