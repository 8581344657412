import {
  Alert,
  Button,
  Checkbox,
  Input,
  InputNumber,
  Space,
  Tooltip,
  Typography,
  Switch,
  ConfigProvider
} from 'antd';
import {
  ButtonContainer,
  EditorContainer,
  TextArea,
  TextAreaContainer
} from '../prettyJsonScreen.styled';
import { AlignLeftOutlined, ApiOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { type ChangeEventHandler, type RefObject } from 'react';
import { type resultInitialState } from '../../../../hooks/constants';
import styles from '../PrettyJsonScreen.module.css';
import { useTranslation } from 'react-i18next';
import { type CheckboxChangeEvent } from 'antd/es/checkbox';
import { type SwitchChangeEventHandler } from 'antd/es/switch';
import { Editor } from '@monaco-editor/react';

interface InputControlProps {
  visible: boolean
  textArea: RefObject<HTMLTextAreaElement>
  data: typeof resultInitialState
  value: string
  isUrl: boolean
  hiddeable: boolean
  ignoreTrailingCommas: boolean
  ignoreComments: boolean
  tabSize: number
  alias: string
  isPost: boolean
  isInvalidBody: boolean
  defaultBody: string | undefined
  onChange: ChangeEventHandler<HTMLTextAreaElement>
  onRequest: () => Promise<void>
  onFormat: () => void
  onMinify: () => void
  onShow: (show: boolean) => void
  onChangeIgnoreTrailingCommas: (e: CheckboxChangeEvent) => void
  onChangeIgnoreComments: (e: CheckboxChangeEvent) => void
  onChangeTabSize: (value: number | null) => void
  onChangeAlias: ChangeEventHandler<HTMLInputElement>
  onChangePost: SwitchChangeEventHandler
  onChangeBody: (value?: string | undefined) => void
}

export default function InputControl ({
  visible,
  textArea,
  data,
  value,
  isUrl,
  hiddeable,
  ignoreTrailingCommas,
  ignoreComments,
  tabSize,
  alias,
  isPost,
  isInvalidBody,
  defaultBody,
  onChange,
  onRequest,
  onFormat,
  onMinify,
  onShow,
  onChangeIgnoreTrailingCommas,
  onChangeIgnoreComments,
  onChangeTabSize,
  onChangeAlias,
  onChangePost,
  onChangeBody
}: InputControlProps) {
  const { t: translation } = useTranslation();

  if (!visible) return null;

  return (
    <>
      <TextAreaContainer>
        <TextArea
          placeholder="Paste your JSON value or URL"
          ref={textArea}
          className={data?.error?.excerpt ? styles.error_warning : ''}
          value={value}
          onChange={onChange}
          style={isUrl ? { height: 60, color: '#3A86FF' } : undefined}
        />
      </TextAreaContainer>
      {
        isPost && (
          <>
            <Typography.Title level={5}>
              { translation('bodyParamsTitle') }
            </Typography.Title>
            <EditorContainer>
              <Editor
                defaultLanguage="json"
                height="150px"
                defaultValue={defaultBody}
                onChange={onChangeBody}
              />
            </EditorContainer>
          </>
        )
      }
      {
        Boolean(data?.error.excerpt) && (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Alert
              message={(
                <strong>
                  {data?.error.reason}
                </strong>
              )}
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              description={(
                <>
                  <div className={styles.error_box}>
                    <div>
                      {` ${data?.error.excerpt ?? ''}`}
                    </div>
                    <div className={styles.pointer}>
                      {` ${data?.error.pointer ?? ''}`}
                    </div>
                    <div className={styles.pointer_start}>
                            ⁞
                    </div>
                  </div>
                  <div className={styles.position}>
                          COLUMN:
                    <strong>{` ${data.error.location.start.column} `}</strong>
                          LINE:
                    <strong>{` ${data.error.location.start.line} `}</strong>
                          POSITION:
                    <strong>{` ${data.error.location.start.offset} `}</strong>
                  </div>
                </>
              )}
              type="error"
            />
          </Space>
        )
      }
      <ButtonContainer>
        <Space>
          <Button
            icon={isUrl ? <ApiOutlined /> : <AlignLeftOutlined />}
            type="primary"
            onClick={isUrl ? onRequest : onFormat}
            disabled={isInvalidBody}
          >
            { isUrl ? translation('requestData') : translation('Formatt') }
          </Button>
          <Button onClick={onMinify} disabled={isUrl}>
                  Minify
          </Button>
          {
            hiddeable && (
              <Button onClick={() => { onShow(false); }}>
                    Hidde original
              </Button>
            )
          }
        </Space>
        <Space direction="vertical" align="end">
          <Space>
            {
              isUrl && (
                <Space className={styles.switch_space}>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: '#389e0d'
                      }
                    }}
                  >
                    <Switch
                      size="small"
                      checked={!isPost}
                      checkedChildren="GET"
                      unCheckedChildren="POST"
                      className={isPost ? styles.post : undefined}
                      onChange={onChangePost}
                    />
                  </ConfigProvider>
                  <Typography.Text>
                    Method
                  </Typography.Text>
                </Space>
              )
            }
            <Checkbox checked={ignoreTrailingCommas} onChange={onChangeIgnoreTrailingCommas}>
              { translation('ignoreTrailingCommas') }
            </Checkbox>
            <Checkbox checked={ignoreComments} onChange={onChangeIgnoreComments}>
                    Ignore comments
            </Checkbox>
          </Space>
          <Space>
            <div>
              <Typography>
                      Identation size
              </Typography>
              <InputNumber
                defaultValue={4}
                value={tabSize}
                onChange={onChangeTabSize}
              />
            </div>
            <div>
              <Typography>
                {'Alias '}
                <Tooltip title="Use mainly for file generation">
                  <QuestionCircleOutlined />
                </Tooltip>
              </Typography>
              <Input
                value={alias}
                onChange={onChangeAlias}
              />
            </div>
          </Space>
        </Space>
      </ButtonContainer>
    </>
  );
}
